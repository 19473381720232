import { initialState } from 'state/initialState';
import { Conversation, FirstMessageOfConversation } from 'types/conversation';
import {
	CREATE_NEW_CONVERSATION,
	GET_ALL_CONVERSATIONS,
	GET_CONVERSATION,
	SET_SELECTED_CONVERSATION,
	CLEAR_CONVERSATION,
	ADD_REVIEW_TO_CONVERSATION,
} from './types';

const initial = { ...initialState.conversations, ...initialState.conversation };

// eslint-disable-next-line default-param-last
export default (state = initial, action: { type: any; payload: Conversation[] }) => {
	switch (action.type) {
		case GET_ALL_CONVERSATIONS: {
			const newData = action.payload.map((conv: Conversation) => {
				const obj = { ...conv };
				obj.artist = JSON.parse(String(conv.artist));
				obj.first_message = JSON.parse(String(conv.first_message));
				obj.rating = JSON.parse(String(conv.rating));
				return obj;
			});
			return {
				...state,
				conversations: newData,
			};
		}
		case GET_CONVERSATION:
			return {
				...state,
				conversation: action.payload,
			};
		case SET_SELECTED_CONVERSATION:
			return {
				...state,
				conversation: action.payload,
			};
		case CLEAR_CONVERSATION:
			return {
				conversation: null,
			};
		case CREATE_NEW_CONVERSATION:
			return {
				...state,
				conversation: action.payload,
			};
		case ADD_REVIEW_TO_CONVERSATION:
			return {
				...state,
				conversation: { ...state.conversation, rating: { ...action.payload } },
			};

		default:
			return state;
	}
};
