import { handleActions } from 'redux-actions';
import { initialState } from 'state/initialState';
import { GET_MESSAGES, CLEAR_MESSAGES } from './types';

const initial = initialState.messages;

const reducer = handleActions(
	{
		[GET_MESSAGES]: (state, { payload }) => {
			return [...payload];
		},
		[CLEAR_MESSAGES]: () => {
			return [];
		},
	},
	initial,
);

export default reducer;
