import { handleActions } from 'redux-actions';
import { initialState } from 'state/initialState';
import { LOGIN, LOGOUT, SET_USER, SIGNUP } from './types';

const initial = initialState.user;

const reducer = handleActions(
	{
		[SIGNUP]: (state, { payload }) => {
			return {
				...state,
				...payload,
			};
		},
		[LOGIN]: (state, { payload }) => {
			return {
				...state,
				...payload,
				isLoggedIn: true,
			};
		},
		[LOGOUT]: (state, { payload }) => {
			return {
				isLoggedIn: false,
			};
		},
		[SET_USER]: (state, { payload }) => {
			return {
				...state,
				...payload,
				isLoggedIn: true,
			};
		},
	},
	initial,
);

export default reducer;
