import { handleActions } from 'redux-actions';
import { initialState } from 'state/initialState';
import { ADD_ERROR_NOTIFICATION, ADD_SUCCESS_NOTIFICATION, CLEAR_NOTIFICATION } from './types';

const initial = initialState.notification;

const reducer = handleActions(
	{
		[ADD_ERROR_NOTIFICATION]: (state, { payload }) => {
			return {
				...state,
				...payload,
			};
		},
		[ADD_SUCCESS_NOTIFICATION]: (state, { payload }) => {
			return {
				...state,
				...payload,
			};
		},
		[CLEAR_NOTIFICATION]: () => {
			return {
				...initial,
			};
		},
	},
	initial,
);

export default reducer;
