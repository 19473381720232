import { handleActions } from 'redux-actions';
import { initialState } from 'state/initialState';
import { GET_SEARCH_DATA, CLEAR_SEARCH_DATA } from './types';

const initial = initialState.searchData;

const reducer = handleActions(
	{
		[GET_SEARCH_DATA]: (state, { payload }) => {
			return [...payload];
		},
		[CLEAR_SEARCH_DATA]: () => {
			return [];
		},
	},
	initial,
);

export default reducer;
