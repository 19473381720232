import { User } from '../types/auth';
import { Artist, ArtistGeneralInfo } from '../types/artists';
import { Skill } from '../types/skills';
import { HomeType } from '../types/home';
import { Search } from '../types/search';
import { Notification, PageLoader } from '../types/common';
import { Conversation, ConversationType } from '../types/conversation';
import { Coachmark } from '../types/coachmark';
import { Message } from '../types/message';

export interface IInitialState {
	user: User;
	artists: Artist[] | [];
	skills: Skill[] | [];
	home: HomeType;
	searchData: Search;
	notification: Notification;
	pageLoader: PageLoader;
	artistInfo: ArtistGeneralInfo;
	conversations: ConversationType;
	conversation: Conversation;
	coachmarks: Coachmark;
	messages: Message[] | [];
}

export const initialState = {
	user: {
		isLoggedIn: false,
	},
	artists: [],
	skills: [],
	home: {},
	searchData: [],
	notification: {
		message: null,
		code: null,
		success: null,
	},
	pageLoader: {
		show: false,
		type: 'dashboard',
	},
	artistInfo: null,
	conversations: {
		conversations: [],
		conversation: {},
	},
	conversation: {},
	coachmarks: {},
	messages: [],
};
