const ACCESS_TOKEN_KEY = 'token';

export const localStorageService = (() => {
	// TOKEN
	function privateSetAccessToken(token: string): void {
		localStorage.setItem(ACCESS_TOKEN_KEY, token);
	}

	function privateGetAccessToken(): string | null {
		return localStorage.getItem(ACCESS_TOKEN_KEY);
	}

	function privateClearAccessToken(): void {
		localStorage.removeItem(ACCESS_TOKEN_KEY);
	}

	// CLEAR STORAGE
	function privateClearStorage(): void {
		localStorage.clear();
	}

	return {
		// TOKEN
		setAccessToken: privateSetAccessToken,
		getAccessToken: privateGetAccessToken,
		clearAccessToken: privateClearAccessToken,

		// CLEAR STORAGE
		clearStorage: privateClearStorage,
	};
})();
