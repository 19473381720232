import { handleActions } from 'redux-actions';
import { GET_ARTIST, RESET_ARTIST, UPDATE_SKILLS } from './types';
import { initialState } from '../../initialState';
import { Artist } from '../../../types/artists';
import { Skill } from '../../../types/skills';

const initial = initialState.artistInfo;

type skillsPayload = {
	skills: Skill[];
	user_liked_skills: number[] | string[];
};

const reducer = handleActions(
	{
		[GET_ARTIST]: (state, { payload }: { payload: Artist }) => {
			return { ...payload };
		},
		[RESET_ARTIST]: (state) => {
			return null;
		},
		[UPDATE_SKILLS]: (state, { payload }: { payload: skillsPayload }) => {
			const liked = payload.user_liked_skills ? [...payload.user_liked_skills] : [];
			return { ...state, skills: [...payload.skills], user_liked_skills: liked };
		},
	},
	initial,
);

export default reducer;
