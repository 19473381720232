import { handleActions } from 'redux-actions';
import { initialState } from 'state/initialState';
import { SET_SKILLS } from './types';

const initial = initialState.skills;

const reducer = handleActions(
	{
		[SET_SKILLS]: (state, { payload }) => {
			return [...state, ...payload];
		},
	},
	initial,
);

export default reducer;
