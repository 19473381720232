import { updateArrayObject } from 'state/helper';
import { initialState } from 'state/initialState';
import { Coachmark } from 'types/coachmark';
import { GET_COACHMARKS, UPDATE_COACHMARK } from './types';

const initial = initialState.coachmarks;

// eslint-disable-next-line default-param-last
export default (state = initial, action: { type: any; payload: any }) => {
	switch (action.type) {
		case GET_COACHMARKS:
			return {
				...state,
				...action.payload,
			};
		case UPDATE_COACHMARK:
			return {
				...state,
				[action.payload]: 1,
				// coachmarks: state.map((coachmark: Coachmark) => {
				// 	if (coachmark.id === action.payload.id) {
				// 		return action.payload;
				// 	}

				// 	return coachmark;
				// }),
			};
		default:
			return state;
	}
};
