import { handleActions } from 'redux-actions';
import { CLEAR_ARTISTS, FETCH_ARTISTS } from './types';
import { initialState } from '../../initialState';

const initial = initialState.artists;

const reducer = handleActions(
	{
		[FETCH_ARTISTS]: (state, { payload }) => {
			return [...state, ...payload];
		},
		[CLEAR_ARTISTS]: () => {
			return [];
		},
	},
	initial,
);

export default reducer;
