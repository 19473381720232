import { handleActions } from 'redux-actions';
import { initialState } from 'state/initialState';
import { GET_HOME_DATA } from './types';

const initial = initialState.home;

const reducer = handleActions(
	{
		[GET_HOME_DATA]: (state, { payload }) => {
			return { ...state, ...payload };
		},
	},
	initial,
);

export default reducer;
