export const GET_ALL_CONVERSATIONS = 'GET_ALL_CONVERSATIONS';
export const GET_ARTIST_FOR_CONVERSATION = 'GET_ARTIST_FOR_CONVERSATION';
export const GET_FIRST_MESSAGE_OF_CONVERSATION = 'GET_FIRST_MESSAGE_OF_CONVERSATION';
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_OF_CUSTOMER_AND_ARTIST = 'GET_MESSAGES_OF_CUSTOMER_AND_ARTIST';
export const GET_SEEN_CONVERSATION_BY_CUSTOMER = 'GET_SEEN_CONVERSATION_BY_CUSTOMER';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const CREATE_REVIEW = 'CREATE_REVIEW';
export const HANDLE_PAYMENT = 'HANDLE_PAYMENT';
export const SET_SELECTED_CONVERSATION = 'SET_SELECTED_CONVERSATION';
export const CREATE_NEW_CONVERSATION = 'CREATE_NEW_CONVERSATION';
export const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION';
export const ADD_REVIEW_TO_CONVERSATION = 'ADD_REVIEW_TO_CONVERSATION';
