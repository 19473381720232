import { handleActions } from 'redux-actions';
import { initialState } from 'state/initialState';
import { SHOW_PAGE_LOADER, HIDE_PAGE_LOADER } from './types';

const initial = initialState.pageLoader;

const reducer = handleActions(
	{
		[SHOW_PAGE_LOADER]: (state, { payload }) => {
			return {
				...state,
				...payload,
			};
		},
		[HIDE_PAGE_LOADER]: () => {
			return {
				...initial,
			};
		},
	},
	initial,
);

export default reducer;
