import axios from 'axios';
import { localStorageService } from '../services/localStorageService';

axios.defaults.baseURL = `${process.env.HOST}/`;

axios.interceptors.request.use(
	(config) => {
		const accessToken = localStorageService.getAccessToken();

		if (config.headers && accessToken) {
			config.headers['access-token'] = accessToken;
			// config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401 || error?.response?.status === 403) {
			// localStorageService.clearAccessToken();
			console.log(error);
		}

		if (error.response?.data) {
			error.message = error.response.data;
		}

		return Promise.reject(error);
	},
);
