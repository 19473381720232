import { combineReducers, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import { initialState } from './initialState';
import user from './common/auth/reducer';
import artists from './common/artists/reducer';
import skills from './common/skills/reducer';
import home from './common/home/reducer';
import searchData from './common/search/reducer';
import notification from './common/notification/reducer';
import artistInfo from './common/artistInfo/reducer';
import conversations from './common/conversations/reducer';
import messages from './common/messages/reducer';
import coachmarks from './common/coachmarks/reducer';
import pageLoader from './common/pageLoader/reducer';

const RootReducer = combineReducers({
	user,
	artists,
	skills,
	home,
	searchData,
	notification,
	artistInfo,
	conversations,
	messages,
	coachmarks,
	pageLoader,
});

const persistConfig = {
	key: 'root',
	blacklist: ['artists', 'artistInfo'],
	storage,
};

const persistedReducer = persistReducer(persistConfig, RootReducer);
const store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
export type AppDispatch = typeof store.dispatch;

export type AppGetState = typeof store.getState;

export default () => {
	const persistor = persistStore(store, null, () => {
		// if you want to get restoredState
		console.log('restoredState', store.getState());
	});
	return { store, persistor };
};
